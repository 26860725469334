:root {
  --otter-bg-color: #777735;
  --app-logo-shadow-color: #c1c173;
}

html, body, #root, .App {
  height: 100%;
}

body {
  background-color: var(--otter-bg-color);
}

/* .App {
} */

.grid-center {
  height: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
}

.App-logo {
  /* pointer-events: none; */
  height: 60vmin;
  border-radius: 50%;
  box-shadow: 0 0 8px 8px var(--app-logo-shadow-color);
  opacity: 0.67;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 4s linear;
  }
}

.App-header {
  background-color: var(--otter-bg-color);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
